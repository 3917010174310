<template>
    <div class="login-content">
        <div class="main-content">
            <div class="left-box">
                <img src="../assets/images/evaluation_login.png" alt="">
            </div>
            <div class="right-box">
                <div class="tip-title">欢迎登录！</div>
                <div class="center-box">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="110px"
                             label-position="left">
                        <el-form-item prop="username" label="账号">
                            <el-input v-model="ruleForm.username" @keyup.enter.native="loginBtn('ruleForm')"
                                      placeholder="请输入账号">
                                <i slot="prefix" class="iconfont">&#xea3d;</i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password" label="密码">
                            <el-input v-model="ruleForm.password" @keyup.enter.native="loginBtn('ruleForm')"
                                      type="password" placeholder="请输入密码">
                                <i slot="prefix" class="iconfont">&#xe82e;</i>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-button size="medium" class="teacher-btn" @click="loginBtn('ruleForm')">登录</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {checkCenterLogin} from "@/utils/apis";

    export default {
        name: "evaluationLogin",
        data() {
            return {
                ruleForm: {
                    username: '',
                    password: '',
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                        {min: 3, max: 90, message: '长度在 3 到 90 个字符', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            loginBtn(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let formData = new FormData()
                        formData.append('username', this.ruleForm.username)
                        formData.append('password', this.ruleForm.password)
                        checkCenterLogin(formData).then((res) => {
                            localStorage.setItem('evaluationToken', res.data.token);
                            localStorage.setItem('evaluationName', res.data.nickname);
                            localStorage.setItem('evaluationAvatar', res.data.avatar);
                            localStorage.setItem('role', res.data.role)
                            localStorage.setItem('hosturl', res.data.hosturl);
                            localStorage.setItem('check_center_id', res.data.check_center_id);
                            this.$message.success({
                                type: 'success',
                                message: res.msg,
                                duration: 1000
                            })
                            this.$router.push('/evaluation')
                        }).catch((err) => {
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .login-content {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../assets/images/evaluation_back.png");
        background-repeat: no-repeat;
        background-size: cover;

        .main-content {
            height: 100%;
            width: 100%;
            margin: 0 160px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-box {
                margin-top: 90px;
                height: calc(100% - 90px);

                img {
                    max-height: 100%;
                }
            }
        }

        .right-box {
            width: 500px;
            height: 580px;
            background: #FFFFFF;
            box-shadow: 0px 0px 18px 3px rgba(18, 30, 87, 0.1);
            border-radius: 8px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            .tip-title {
                position: absolute;
                left: 60px;
                top: 100px;
                font-size: 24px;
                color: #060211;
            }

            .center-box {
                margin: 200px 60px 0;
                width: calc(100% - 120px);
            }

            .el-button {
                width: 100%;
                background: #2DC079;
                color: #fff;
                font-size: 16px;
                height: 40px;
                border-radius: 4px;
                margin-top: 66px
            }

            ::v-deep .el-form {
                .el-form-item {
                    margin-bottom: 5px;
                }

                .el-form-item__label:before {
                    content: '';
                }

                .el-form-item__content {
                    margin-left: 0 !important;
                    width: 100%;
                }
            }
        }
    }
</style>